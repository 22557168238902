import './App.css';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { AmplifySignOut, AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import QRCode from './components/qr';
import React from 'react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

Amplify.configure(awsconfig);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "VerifyAPI",
        endpoint: "https://eu4svlt4fh.execute-api.us-east-1.amazonaws.com/prod/verify/"
      }
    ]
  },
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_OHGPM6OHW',
    userPoolWebClientId: '6ri35oo0mc7lqmg8eve6mgi68i',
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH'
  }
});

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
      return onAuthUIStateChange((nextAuthState, authData) => {
          setAuthState(nextAuthState);
          setUser(authData)
      });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <div>
      <header className="App-header">
          <AmplifySignOut />
          <img src="/welcomepass.png" className="App-logo" alt="logo" />
      </header>
      <body className="App-body">
        <QRCode />
      </body>
    </div>
  ) : (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp 
      slot="sign-up"
      usernameAlias="email"
      formFields={[
        { type: "email" },
        { type: "password" },
      ]}/>
    </AmplifyAuthenticator>
  );
}

export default AuthStateApp;

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//           <img src="/welcomepass.png" className="App-logo" alt="logo" />
//           <AmplifySignOut />
//           <QRCode />
//       </header>
//     </div>
//   );
// }

// export default withAuthenticator(App, {
//   usernameAlias: 'email'
// });
