import React, { Component, Fragment } from 'react'
import QRScan from 'qrscan';
import { Auth, API } from 'aws-amplify';
import '../index.css';

async function postData(qrcode) {
  const apiName = 'VerifyAPI';
  const path = '';
  const myInit = {
    headers: {
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {'qrcode': qrcode}
  };

  const response = await API.post(apiName, path, myInit);
  return response;
}

class QRCode extends Component {

  constructor (props) {
    super(props)
    this.state = { value: '', watching: false, userinfo: undefined }
    this.onFind = this.onFind.bind(this)
  }
  
  async onFind (value) {
    if (this.state.watching){
      const data = await postData(value);
      await this.setState({ value: '', watching: false, userinfo: data }, () => {
        console.log("this.state.userinfo" + JSON.stringify(this.state));
        return;
      }); 
    }
  }

  render () {
    return (
      <Fragment>
        <h2>SMART Health Card Verifier</h2>
        {this.state.watching
          ? (
            <QRScan onFind={this.onFind} />          
          )
          : (
            <Fragment>
              <button onClick={async () => {await this.setState({ value: '', watching: true, userinfo: undefined })}}>Scan QR Code</button>
            </Fragment>
          )
        }
        {!this.state.userinfo
         ? (
          <div id="results"></div>
         )
         : (
          <Fragment>
              {this.state.userinfo.status === 'Not Verified'
              ? (
                <div id="results"> 
                  <p class="status-font"> Status: {this.state.userinfo.status}  </p>
                </div>
              ) : (
                <div id="results"> 
                    <p class="status-font"> Status: {this.state.userinfo.status}  </p>
                    <div id="person">
                      <p class="heading-font"> Personal Details </p>
                      <p class="details-font"> Name: {this.state.userinfo.details.name}  </p>
                      <p class="details-font"> Date of Birth: {this.state.userinfo.details.dob}  </p>
                      <p class="details-font"> Issuer: {this.state.userinfo.details.issuer}  </p>
                    </div>
                    <div id="dose1">
                      <p class="heading-font"> Dose 1 </p>
                      <p class="details-font"> Manufacturer: {this.state.userinfo.details.manufacturer1stdose} </p>
                      <p class="details-font"> Date: {this.state.userinfo.details.date1stdose}  </p>
                      <p class="details-font"> Place: {this.state.userinfo.details.place1stdose}  </p>
                    </div>
                    {this.state.userinfo.details.date2nddose &&
                      <div id="dose2">
                        <p class="heading-font"> Dose 2 </p>
                        <p class="details-font"> Manufacturer: {this.state.userinfo.details.manufacturer2nddose} </p>
                        <p class="details-font"> Date: {this.state.userinfo.details.date2nddose}  </p>
                        <p class="details-font"> Place: {this.state.userinfo.details.place2nddose}  </p>
                      </div>
                    }
                </div>                
              )}
          </Fragment>
         )
        }
      </Fragment>
    )
  }
}

export default QRCode;